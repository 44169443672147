import React, { useContext, useState } from 'react';
import {
  Box,
  SimpleGrid,
  Text,
  VStack,
  HStack,
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  Button,
} from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import InstagramProfilePic from './InstagramProfilePic';
import { FaStar } from 'react-icons/fa';
import SubmissionsModal from './SubmissionsModal';
import { Influencer, CollaborationsData } from '../types';

interface ActiveCampaignProps {
  campaignName: string;
  campaignId: string;
  collaborations: Record<string, CollaborationsData>;
  influencers: Record<string, Influencer>;
  clientID: string;
}

const ActiveCampaign: React.FC<ActiveCampaignProps> = ({
  campaignName,
  campaignId,
  collaborations,
  influencers,
  clientID
}) => {
  const history = useHistory();
  const [isSubmissionsModalOpen, setIsSubmissionsModalOpen] = useState(false);
  const [selectedCollaboration, setSelectedCollaboration] = useState<CollaborationsData | null>(null);
  
  const boxContents = [
    { label: 'Likes', value: '0' },
    { label: 'Comments', value: '0' },
    { label: 'Posts', value: '0' },
    { label: 'Shares', value: '0' },
  ];

  const handleViewSubmissions = (collaboration: CollaborationsData) => {
    setSelectedCollaboration(collaboration);
    setIsSubmissionsModalOpen(true);
  };

  const sortedCollaborations = Object.values(collaborations).sort((a, b) => {
    const priorityStatuses = ['collabCompleted'];
    if (priorityStatuses.includes(a.status) && !priorityStatuses.includes(b.status)) {
      return -1;
    }
    if (!priorityStatuses.includes(a.status) && priorityStatuses.includes(b.status)) {
      return 1;
    }
    return 0;
  });

  return (
    <Box bg="white" overflowY="auto" p={0} display="flex" flexDirection="column" minHeight="calc(100vh - 135px)">
      <Box width="90%" mx="auto" my={6} display="flex" flexDirection="column" height="calc(100vh - 48px)">
        <Box position="relative" h="20%" bg="#B8E79E" borderRadius="lg" mb={6}>
          <Text
            position="absolute"
            top={4}
            left={6}
            fontSize="2xl"
            fontWeight="bold"
            color="black"
          >
            {campaignName}
          </Text>
          <Button
            position="absolute"
            top={4}
            right={6}
            bg="black"
            color="white"
            _hover={{ bg: "gray.800" }}
            onClick={() => history.push(`/admin/foodfluence/search?campaignID=${campaignId}&clientID=${clientID}`)}
          >
            Add Influencers
          </Button>
        </Box>
        <Flex justifyContent="center" mt="-8%" mb={6}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6} width="95%">
            {boxContents.map(({ label, value }, index) => (
              <Box
                key={index}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                p={6}
                position="relative"
                height={{ base: '130px', md: '150px' }}
              >
                <VStack align="flex-start" spacing={3} height="100%">
                  <Text
                    fontSize={{ base: 'sm', md: 'md' }}
                    fontWeight="bold"
                    color="gray.700"
                  >
                    {label}
                  </Text>
                  <VStack align="flex-start" spacing={0} mt="auto">
                    <HStack spacing={2} alignItems="baseline">
                      <Text
                        fontSize={{ base: '2xl', md: '3xl' }}
                        fontWeight="bold"
                        color="black"
                      >
                        {value}
                      </Text>
                    </HStack>
                  </VStack>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Flex>
        <Text
          fontSize="xl"
          fontWeight="bold"
          color="black"
          mb={4}
          pl={4}
        >
          Collaborations
        </Text>
        <Box overflowY="auto" flex={1} px={2} mb={8}>
          <Table variant="simple">
            <Tbody>
              {sortedCollaborations.map((collaboration) => {
                const influencer = influencers[collaboration.influencerID];
                return (
                  <Tr key={collaboration.id}>
                    <Td py={4}>
                      <Flex alignItems="center" gap={6}>
                        <InstagramProfilePic
                          profile_pic_url={influencer.profile_pic_url}
                          instagramHandle={influencer.instagramHandle}
                          imageSize="89px"
                        />
                        <VStack align="flex-start" spacing={1}>
                          <Flex alignItems="center" gap={2}>
                            <Text fontWeight="bold">@{influencer.instagramHandle}</Text>
                            {influencer.featured && <FaStar color="#0080FE" size={16} />}
                          </Flex>
                          <Text fontSize="sm" color="gray.600">
                            {influencer?.city && influencer?.state ? `${influencer.city}, ${influencer.state} | ` : ''}
                            {influencer?.follower_count.toLocaleString()} followers
                          </Text>
                        </VStack>
                      </Flex>
                    </Td>
                    <Td textAlign="right">
                      <Button 
                        bg={collaboration.status === 'collabCompleted' ? "#B8E79E" : "red.300"}
                        color="black" 
                        _hover={{ bg: collaboration.status === 'collabCompleted' ? "#A6D08D" : "red.400" }} 
                        size="md"
                        px={6}
                        onClick={collaboration.status === 'collabCompleted' ? () => handleViewSubmissions(collaboration) : () => {}}
                      >
                        {collaboration.status === 'collabCompleted' ? 'View Submissions' : 'Awaiting Submission'}
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Box>
      {selectedCollaboration && (
        <SubmissionsModal
          isOpen={isSubmissionsModalOpen}
          onClose={() => setIsSubmissionsModalOpen(false)}
          collaboration={selectedCollaboration}
          influencer={influencers[selectedCollaboration.influencerID]}
        />
      )}
    </Box>
  );
};

export default ActiveCampaign;