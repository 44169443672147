import React from "react";
import { Box, Button, Text } from "@chakra-ui/react";

interface PaginationProps {
  currPage: number;
  setCurrPage: (page: number) => void;
  totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({ currPage, setCurrPage, totalPages }) => {
  const displayPageNumbers = () => {
    if (totalPages <= 5) {
      // Show all pages if there are 5 or fewer pages
      return Array.from({ length: totalPages }, (_, index) => (
        <PageButton key={index} page={index} currPage={currPage} setCurrPage={setCurrPage} />
      ));
    }

    if (currPage < 2) {
      // Show first few pages and last page
      return (
        <>
          {Array.from({ length: 4 }, (_, index) => (
            <PageButton key={index} page={index} currPage={currPage} setCurrPage={setCurrPage} />
          ))}
          <Text mx={1} color="gray.500">...</Text>
          <PageButton page={totalPages - 1} currPage={currPage} setCurrPage={setCurrPage} />
        </>
      );
    }

    if (currPage > totalPages - 4) {
      // Show last few pages and first page
      return (
        <>
          <PageButton page={0} currPage={currPage} setCurrPage={setCurrPage} />
          <Text mx={1} color="gray.500">...</Text>
          {Array.from({ length: 4 }, (_, index) => (
            <PageButton key={totalPages - 4 + index} page={totalPages - 4 + index} currPage={currPage} setCurrPage={setCurrPage} />
          ))}
        </>
      );
    }

    // Show middle pages with "..." for start and end
    return (
      <>
        <PageButton page={0} currPage={currPage} setCurrPage={setCurrPage} />
        <Text mx={1} color="gray.500">...</Text>
        {Array.from({ length: 3 }, (_, index) => (
          <PageButton key={currPage - 1 + index} page={currPage - 1 + index} currPage={currPage} setCurrPage={setCurrPage} />
        ))}
        <Text mx={1} color="gray.500">...</Text>
        <PageButton page={totalPages - 1} currPage={currPage} setCurrPage={setCurrPage} />
      </>
    );
  };

  return (
    <Box 
      display="flex" 
      alignItems="center" 
      justifyContent="center" 
      py={4}
      bg="white"
    >
      {/* Previous Arrow */}
      <Button
        onClick={() => setCurrPage(currPage - 1)}
        disabled={currPage === 0}
        bg="transparent"
        color="black"
        mr={2}
        _hover={{ bg: 'gray.100' }}
      >
        &lt;
      </Button>

      {/* Page Numbers */}
      {displayPageNumbers()}

      {/* Next Arrow */}
      <Button
        onClick={() => setCurrPage(currPage + 1)}
        disabled={currPage === totalPages - 1}
        bg="transparent"
        color="black"
        ml={2}
        _hover={{ bg: 'gray.100' }}
      >
        &gt;
      </Button>
    </Box>
  );
};

const PageButton: React.FC<{ page: number; currPage: number; setCurrPage: (page: number) => void }> = ({ page, currPage, setCurrPage }) => {
  const isCurrentPage = page === currPage;

  return (
    <Button
      onClick={() => setCurrPage(page)}
      bg={isCurrentPage ? "#CDFBB1" : "transparent"}
      color={isCurrentPage ? "black" : "black"}
      mx={1}
      _hover={{ bg: isCurrentPage ? "blue.600" : "gray.100" }}
      borderRadius="full"
      minW={8}
      h={8}
    >
      {page + 1}
    </Button>
  );
};

export default Pagination;