import React from 'react';
import { Flex,Text, Button,Checkbox } from '@chakra-ui/react';
import InstagramProfilePic from '../../../components/InstagramProfilePic';
import { updateInfluencerBookmarkedByClient } from 'services/firebaseService';
import { FaInstagram, FaEnvelope, FaTiktok, FaStar } from "react-icons/fa6";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { Influencer } from '../../../types';

interface InfluencersListProps {
  influencers: Influencer[];
  bookmarkedInfluencers: String[];
  clientID: string;
  creatingCampaign: boolean;
  handleInfluencerSelected: (influencerID: string) => void;
  selectedInfluencers: Set<String>;
}

const InfluencersList: React.FC<InfluencersListProps> = (props) => {
  const { influencers, bookmarkedInfluencers, clientID, creatingCampaign, handleInfluencerSelected, selectedInfluencers } = props;

  function formatFollowersCount(followers: number): string {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + "k";
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + "M";
    }
    return followers.toString();
  }
  return (
<>
{influencers.map((influencer) => (
    <Flex key={influencer.id} direction="row" gap={4} mt={6} align="center">
      {creatingCampaign?(
        <Checkbox key={influencer.id} isChecked={selectedInfluencers.has(influencer.id)}
        onChange={()=>handleInfluencerSelected(influencer.id)}
        colorScheme='green' 
        border={selectedInfluencers.has(influencer.id)?"0px solid":"1px solid"}
        borderRadius="sm"
        size='md'
        />)
        :(<></>)}
      <InstagramProfilePic profile_pic_url={influencer.profile_pic_url}
                          instagramHandle={influencer.instagramHandle} imageSize={"100px"} />
      <Flex direction="column">
        <Flex direction="row" gap={2} alignItems="center">
          <Text fontSize={"xl"} fontWeight="semibold">
            @{influencer.instagramHandle}
          </Text>
          {influencer.featured ? (
            <FaStar color="#0080FE" style={{ verticalAlign: 'middle' }} />
          ) : null}
          <Button style={{ verticalAlign: 'middle' }} onClick={() => updateInfluencerBookmarkedByClient(clientID, influencer.id)} variant="ghost" fontSize="20px">
            {bookmarkedInfluencers && bookmarkedInfluencers.includes(influencer.id) ?
              (<FaBookmark color="green" />) : (<FaRegBookmark />)}
          </Button>
        </Flex>
        <Flex direction="row" gap={2}>
          {influencer.email_address ?(
              <Button
                as="a"
                href={`mailto:${influencer.email_address}`}
                variant="link"
                color="gray.500"
                fontWeight="normal"
                textDecoration="none"
                size={"lg"}
              >
                <FaEnvelope />
              </Button>

             
          ):<></>}
      {influencer.email_address ? (
         <Text as="span" color="gray.500">
         |
       </Text>):<></>}
          {influencer.instagramHandle ? (
              <Button
                as="a"
                href={`https://instagram.com/${influencer.instagramHandle}`}
                variant="link"
                color="gray.500"
                fontWeight="normal"
                textDecoration="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </Button>
            ):<></>}
            {influencer.instagramHandle ? (
                <Text as="span" color="gray.500">
                |
              </Text>):<></>}
          {influencer.tiktokHandle ? (
              <Button
                as="a"
                href={`https://www.tiktok.com/@${influencer.tiktokHandle}`}
                variant="link"
                color="gray.500"
                fontWeight="normal"
                textDecoration="none"
                target="_blank"
                rel="noopener noreferrer"
                size={"lg"}
              >
                <FaTiktok />
              </Button>
               ):<></>}
               {influencer.tiktokHandle ? (
                <Text as="span" color="gray.500">
                |
              </Text>):<></>}
          {influencer.state ? (
          <Text as="span" color="gray.500">
            {influencer.city!=""?influencer.city + ", ":""}{influencer.state}
          </Text>):(<></>)}
          {influencer.state ? (<Text as="span" color="gray.500">
            |
          </Text>):(<></>)}

          <Text as="span" color="gray.500">
            {influencer.follower_count?formatFollowersCount(influencer.follower_count) + " followers":""}
          </Text>
        </Flex>
      </Flex>
    </Flex>
    ))}
    </>)
};

  export default InfluencersList;