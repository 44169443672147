import React, { useState } from 'react';
import { Box, Button, Text, Flex, Container } from '@chakra-ui/react';
import Analytics from './analytics';
import CreateCampaignModal from './CreateCampaignModal';

interface WelcomeBannerProps {
  clientID: string; // Add clientID prop
  accountName: string;
}

const WelcomeBanner: React.FC<WelcomeBannerProps> = ({ clientID, accountName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box position="relative" width="100%"> {/* Set width to 100% for full width */}
      <Container maxW="container.2xl" px={{ base: 4, md: 6 }}> {/* Increased maxW to container.2xl for a wider banner */}
        <Box
          bg="black"
          color="white"
          p={{ base: 4, md: 6, lg: 8 }}
          borderRadius="md"
          mb={4}
          minHeight={{ base: '20vh', sm: '16vh' }}
          position="relative"
          zIndex={1}
          width="100%" // Ensure the inner box takes full width
        >
          <Flex
            justifyContent="space-between"
            alignItems={{ base: 'flex-start', sm: 'center' }}
            flexDirection={{ base: 'column', sm: 'row' }}
            mb={{ base: 4, sm: 0 }}
          >
            <Text 
              fontSize={{ base: 'xl', sm: '2xl' }} 
              fontWeight="bold" 
              mb={{ base: 4, sm: 0 }}
            >
              Welcome Back, {accountName}!
            </Text>
            <Button
              bg="#CDFBB1"
              color="black"
              _hover={{ bg: "#B8E79E" }}
              onClick={handleOpenModal}
              size="md"
              fontSize={{ base: 'sm', sm: 'md' }}
              px={{ base: 3, sm: 4 }}
              py={{ base: 2, sm: 3 }}
            >
              Start New Campaign
            </Button>
          </Flex>
        </Box>
      </Container>
      <Box 
        position="relative"
        mt={{ base: 4, sm: '-8vh' }}
        mx="auto"
        maxWidth="container.2xl" 
        px={[4, 6]}
        zIndex={2}
      >
        <Analytics clientID={clientID} /> {/* Pass clientID to Analytics */}
      </Box>
      <CreateCampaignModal clientID={clientID} isOpen={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default WelcomeBanner;