class Environment {
  static FLASK_HOST_URL: string;
  static MANUAL_VERIFICATION_HOST_URL: string;
  static FLASK_API_KEY: string;
  static STRIPE_SECRET_KEY: string;
  static GOOGLE_API_KEY: string;
  static USE_TEST_MODE: boolean;
  static FACEBOOK_APP_ID: string;
  static FACEBOOK_APP_SECRET: string;
  static FACEBOOK_BASE_URL: string;

  static initialize(): void {
    const useTestModeString: string =
      process.env.REACT_APP_USE_TEST_MODE || "false";
    this.USE_TEST_MODE = useTestModeString.toLowerCase() === "true";
    this.FACEBOOK_APP_ID =
      process.env.REACT_APP_FACEBOOK_APP_ID ||
      "U2FsdGVkX1+uNWnidTYXSUykOWeoh/dGmru3faEEd30N4k4Rce0RAUw6zz005lbo";
    this.FACEBOOK_APP_SECRET = process.env.REACT_APP_FACEBOOK_APP_SECRET;
    this.FACEBOOK_BASE_URL = process.env.REACT_APP_FACEBOOK_BASE_URL;
    if (process.env.NODE_ENV === "production") {
      this.FLASK_HOST_URL = this.USE_TEST_MODE
        ? "https://storyitapp-dev.uc.r.appspot.com"
        : "https://storyitapp.uc.r.appspot.com";
    } else {
      this.FLASK_HOST_URL = "https://storyitapp-dev.uc.r.appspot.com";
    }

    console.log("FLASK_HOST_URL: " + this.FLASK_HOST_URL);
    if (this.USE_TEST_MODE) {
      console.warn("Using test mode!");
      this.GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY_TEST ?? "";
      this.STRIPE_SECRET_KEY =
        process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST ?? "";
      this.MANUAL_VERIFICATION_HOST_URL =
        "https://manualverification-storyit-dev.web.app/";
    } else {
      this.GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY_PROD ?? "";
      this.STRIPE_SECRET_KEY =
        process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD ?? "";
      this.MANUAL_VERIFICATION_HOST_URL =
        "https://manualverification-storyit.web.app/";
    }

    this.FLASK_API_KEY = process.env.REACT_APP_FLASK_API_KEY ?? "";

    if (process.env.NODE_ENV === "production" && !process.env.USE_TEST_MODE) {
      console.log = function () {}; // disable console.log in production
    }
  }
}

export default Environment;
