import React, {useState, useRef} from 'react';
import {
  Button,
  Flex,
  Box,
  Input,
  Textarea,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  Select,
  Stack,
  Link
} from '@chakra-ui/react';
import { useHistory, Route, Switch } from 'react-router-dom';
import { FaPlus, FaMinus, FaEye, FaRedo } from 'react-icons/fa';
import { createNewInfluencerCampaign, uploadCollabImage } from 'services/firebaseService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdUpload,MdFindReplace,MdFileDownload, MdDateRange } from "react-icons/md";

interface CreateCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientID: String;
}

interface DeliverableCounts {
    tiktoks: Number;
    instagramReels: Number;
    instagramStories: Number;
    instagramPosts: Number;
}
const CreateCampaignModal: React.FC<CreateCampaignModalProps> = ({ isOpen, onClose, clientID }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // State to manage content type counts
  const [tikTokCount, setTikTokCount] = useState(0);
  const [instagramStoryCount, setInstagramStoryCount] = useState(0);
  const [instagramReelCount, setInstagramReelCount] = useState(0);
  const [instagramPostCount, setInstagramPostCount] = useState(0);
  const [campaignName, setCampaignName] = useState('');
  const [campaignDescription, setCampaignDescription] = useState('');
  const [rewardType, setRewardType] = useState<'cash' | 'diningCredit'>('cash');
  const [compensation, setCompensation] = useState<string>('0'); // Changed to string to allow backspace
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [collabImage, setCollabImage] = useState<string | null>(null); // Changed to string
  const [showUploadButton, setShowUploadButton] = useState(true); // State to manage upload button visibility

  const history = useHistory();

  const handleFormSubmission = async() => {
    const deliverableCounts: DeliverableCounts = {
        'tiktoks' : tikTokCount,
        'instagramReels' : instagramReelCount,
        'instagramStories' : instagramStoryCount,
        'instagramPosts' : instagramPostCount,
      };
    
   const campaignID = await createNewInfluencerCampaign(clientID, campaignName, campaignDescription, deliverableCounts, rewardType, Number(compensation), endDate, collabImage)
   onClose();
   history.push(`/admin/foodfluence/search?campaignID=${campaignID}&clientID=${clientID}`);
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const arrayBuffer = await file.arrayBuffer(); // Convert file to ArrayBuffer
      const uint8Array = new Uint8Array(arrayBuffer); // Convert ArrayBuffer to Uint8Array

      const collabImageURL = await uploadCollabImage(clientID, campaignName, uint8Array); // Upload Uint8Array to Firebase
      setCollabImage(collabImageURL); // Set the state with the uploaded image URL
      setShowUploadButton(false); // Hide the upload button after successful upload
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="80vw" maxWidth={"800px"} border="2px solid green.500">
        <ModalHeader>Create a Campaign</ModalHeader>
        <ModalBody>
          <Box mb={4}>
            <Text>Campaign Name</Text>
            <Input
              placeholder="Collaboration Name"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              mb={4}
            />
            <Text>Describe the campaign</Text>
            <Textarea
              placeholder="What are you looking to create with this campaign?"
              value={campaignDescription}
              onChange={(e) => setCampaignDescription(e.target.value)}
              mb={4}
            />
            <Text fontWeight="bold" mb={2}>Content Types:</Text>
            <Stack spacing={4}>
              {[
                { label: 'TikTok', count: tikTokCount, setCount: setTikTokCount },
                { label: 'Instagram Story', count: instagramStoryCount, setCount: setInstagramStoryCount },
                { label: 'Instagram Reel', count: instagramReelCount, setCount: setInstagramReelCount },
                { label: 'UGC Video', count: instagramPostCount, setCount: setInstagramPostCount }
              ].map(({ label, count, setCount }) => (
                <Flex key={label} alignItems="center" justifyContent="space-between">
                  <Text>{label}</Text>
                  <Flex alignItems="center">
                    <IconButton
                      icon={<FaMinus style={{ color: 'red' }} />}
                      aria-label={`Decrease ${label} count`}
                      onClick={() => count > 0 && setCount(count - 1)}
                    />
                    <Text mx={2}>{count}</Text>
                    <IconButton
                      icon={<FaPlus style={{ color: 'green' }} />} // Changed color to green
                      aria-label={`Increase ${label} count`}
                      onClick={() => setCount(count + 1)}
                    />
                  </Flex>
                </Flex>
              ))}
            </Stack>
            <Box mt={4}>
              <Text fontWeight="bold" mb={2}>Reward Type:</Text>
              <Select
                value={rewardType}
                onChange={(e) => setRewardType(e.target.value as 'cash' | 'diningCredit')}
              >
                <option value="cash">Cash</option>
                <option value="diningCredit">Dining Credit</option>
              </Select>
            </Box>
            <Box mt={4}>
              <Input
                type="text"
                placeholder="Amount"
                value={compensation}
                onChange={(e) => setCompensation(e.target.value)}
              />
            </Box>
            <Box mt={4}>
              <Text fontWeight="bold" mb={2}>End Date:</Text>
              <DatePicker
                id="endDatePicker"
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                dateFormat="MMMM d, yyyy"
                minDate={new Date()}
                placeholderText="Select end date"
              />
            </Box>
            <Flex alignItems="center" justifyContent="space-between" mt={4}>
              <Text fontWeight="bold" mb={2}>Upload Image (Optional):</Text>
              {showUploadButton ? (
                <Button
                  rightIcon={<MdUpload />}
                  aria-label="Upload Image"
                  onClick={() => inputRef.current?.click()}
                  bg="black" // Changed background color to black
                  color="white" // Changed text color to white
                  position="relative" // Lowered the position of the button by a couple of pixels
                  mt={2} // Decreased margin top for the upload button
                  _hover={{ bg: "blackAlpha.600" }} // Added hover effect for a subtle depth
                >
                  Upload
                </Button>
              ) : (
                <Flex>
                  <Link href={collabImage} isExternal>
                    <IconButton icon={<FaEye size={30} />} aria-label="View Image" color="green" />
                  </Link>
                  <Button
                    ml={2}
                    bg="red.500"
                    color="white"
                    _hover={{ bg: "red.600" }}
                    onClick={() => setShowUploadButton(true)}
                    borderRadius="full"
                  >
                    <FaRedo size={12} />
                  </Button>
                </Flex>
              )}
            </Flex>
            <Input
              type="file"
              ref={inputRef}
              display="none"
              onChange={handleFileChange} // Changed to handleFileChange function
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={() => handleFormSubmission()}>
            Create
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateCampaignModal;
