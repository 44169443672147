import React, { createContext, useState, useEffect } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../firebase";

export const InfluencerCampaignsDataContext = createContext();

export const InfluencerCampaignsDataProvider = ({ children, clientIds }) => {
    const [influencerCampaigns, setInfluencerCampaigns] = useState({});

    useEffect(() => {
        const influencerCollectionRef = collection(firestore, "influencerCampaigns");
        const collaborationsRef = collection(firestore, "collabs");
        if (!clientIds || clientIds.length === 0) return;
        const campaignQuery = query(influencerCollectionRef, where("clientID", "in", clientIds));

        const unsubscribeCampaigns = onSnapshot(campaignQuery, async (campaignSnapshot) => {
            const fetchedInfluencerCampaigns = {};
            const campaignIds = [];

            campaignSnapshot.forEach((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const clientID = data.clientID;
                    if (!fetchedInfluencerCampaigns[clientID]) {
                        fetchedInfluencerCampaigns[clientID] = {};
                    }
                    fetchedInfluencerCampaigns[clientID][docSnap.id] = { id: docSnap.id, ...data, collaborations: {} };
                    campaignIds.push(docSnap.id);
                }
            });
         
            const collaborationsQuery = query(collaborationsRef, where("influencerCampaignID", "in", campaignIds));
            const unsubscribeCollaborations = onSnapshot(collaborationsQuery, (collabSnapshot) => {
                collabSnapshot.forEach((collabDoc) => {
                    if (collabDoc.exists()) {
                        const collabData = collabDoc.data();
                        const campaignId = collabData.influencerCampaignID;
                        
                        // Find the correct client ID for this campaign
                        for (const clientID in fetchedInfluencerCampaigns) {
                            if (fetchedInfluencerCampaigns[clientID][campaignId]) {
                                fetchedInfluencerCampaigns[clientID][campaignId].collaborations[collabDoc.id] = {
                                    id: collabDoc.id,
                                    ...collabData
                                };
                                break;
                            }
                        }
                    }
                });

                console.log("fetchedInfluencerCampaigns", fetchedInfluencerCampaigns);
                setInfluencerCampaigns(fetchedInfluencerCampaigns);
            });

            // Return a cleanup function that unsubscribes from both listeners
            return () => {
                unsubscribeCampaigns();
                unsubscribeCollaborations();
            };
        });

        // Cleanup the campaign listener when the component is unmounted
        return () => {
            unsubscribeCampaigns();
        };
    }, [clientIds]);

    return (
        <InfluencerCampaignsDataContext.Provider value={influencerCampaigns}>
            {children}
        </InfluencerCampaignsDataContext.Provider>
    );
};

export default InfluencerCampaignsDataProvider;
