import CryptoJS from "crypto-js";

const secretKey = "STOR12321@453%_&422ISNMSNA1$21FG"; // Make sure this is kept secure

export const convertTimetoLocal = (timestamp) => {
  if (!timestamp) {
    return "N/A";
  }
  const date = new Date(timestamp);
  // Log the date in your local timezone
  return date.toLocaleString();
};

// Encrypt a text
export const encryptKey = (text) => {
  return CryptoJS?.AES?.encrypt(text, secretKey)?.toString();
};

// Decrypt a text
export const decryptKey = (cipherText) => {
  const bytes = CryptoJS?.AES?.decrypt(cipherText, secretKey);
  return bytes?.toString(CryptoJS.enc.Utf8);
};

export const decimalFraction = (inputVal: any) => {
  // if (inputVal && !Number(inputVal)) return 0;
  // Extract the value entered by the user
  let value = inputVal;

  // Remove any non-numeric characters except for dot
  value = value?.replace(/[^0-9.]/g, "");

  // Remove leading zeros
  value = value?.replace(/^0+/, "");

  // Limit decimal digits to 4 and prevent multiple consecutive dots
  const parts = value?.split(".");
  if (parts.length > 1) {
    // Keep only the first occurrence of dot and limit decimal digits to 4
    parts[1] = parts[1]?.replace(/\./g, "")?.slice(0, 2);
    value = parts[0] + "." + parts[1];
  }
  return value;
};

// Convert value into number  only
export const valueToNumberConvert = (inputVal: any) => {
  // Extract the value entered by the user
  let value = inputVal;
  value = value?.replace(/[^0-9]/g, "");

  // Convert and return the value to an integer
  return parseInt(value);
};

// check if input contains emojis or not

export const checkForEmojiInString = (inputVal: any) => {
  const emojiRegex =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
  return !emojiRegex?.test(inputVal);
};

export const toTitleCase = (str: string) => {
  return str?.replace(/\b\w/g, function (char) {
    return char?.toUpperCase();
  });
};

export const strToFloat = (str: any) => {
  if (!str) return 0;
  return parseFloat(str?.toString());
};

export const contentTypeText = (contentType: string) => {
  let contentText = "";
  switch (contentType) {
    case "instagramStory":
      contentText = "Instagram Story";
      break;
    case "ugcPicture":
      contentText = "UGC Picture";
      break;
    case "ugcVideo":
      contentText = "UGC Video";
      break;
    case "tiktokVideo":
      contentText = "Tiktok Video";
      break;
    case "instagramReel":
      contentText = "Instagram Reel";
      break;
    case "instagramPost":
      contentText = "Intagram Post";
      break;
    default:
      break;
  }
  return contentText;
};

export const cashSummary = (data) => {
  const {
    quantity,
    minimumFollowerCount,
    specificProduct = "",
    accountName,
    instagramHandle,
    contentType,
    compensation,
    compensationType,
  } = data;
  let summary = "";
  let contentText = "";

  switch (contentType) {
    case "instagramStory":
      contentText = "story";
      break;
    case "ugcPicture":
      contentText = "UGC Picture";
      break;
    case "ugcVideo":
      contentText = "UGC Video";
      break;
    case "tiktokVideo":
      contentText = "video";
      break;
    case "instagramReel":
      contentText = "reel";
      break;
    case "instagramPost":
      contentText = "picture";
      break;
    default:
      break;
  }

  if (contentType === "ugcPicture") {
    summary = `${quantity} customers will have the opportunity to upload 1 picture of ${
      specificProduct?.trim()?.length > 0
        ? specificProduct
        : "(specific product)"
    } from ${accountName} to earn ${compensationType === "cash" ? "$" : ""}${
      compensation ? compensation : 0
    }${compensationType === "cashback" ? "%" : ""}.`;
  } else if (contentType === "ugcVideo") {
    summary = `${quantity} customers will have the opportunity to upload 1 video of ${
      specificProduct?.trim()?.length > 0
        ? specificProduct
        : "(specific product)"
    } from ${accountName} to earn ${compensationType === "cash" ? "$" : ""}${
      compensation ? compensation : 0
    }${compensationType === "cashback" ? "%" : ""}.`;
  } else {
    if (specificProduct) {
      summary = `${quantity} customers will have the opportunity to post 
            1 picture of ${
              specificProduct?.trim()?.length > 0
                ? "" + specificProduct?.trim()
                : accountName
            } and tag @${instagramHandle} on their 
             ${contentTypeText(contentType)} to earn ${
        compensationType === "cash" ? "$" : ""
      }${compensation ? compensation : 0}${
        compensationType === "cashback" ? "%" : ""
      }.`;
    } else {
      summary = `${quantity} customers who have at least
            ${minimumFollowerCount} followers will have the opportunity to post 
            1 ${contentText} of ${accountName} and tag @${instagramHandle} on their 
             ${contentTypeText(contentType)} to earn ${
        compensationType === "cash" ? "$" : ""
      }${compensation ? compensation : 0}${
        compensationType === "cashback" ? "%" : ""
      }.`;
    }
  }
  return summary;
};

export const cashBackSummary = (data) => {
  const {
    quantity,
    minimumFollowerCount,
    specificProduct = "",
    accountName,
    instagramHandle,
    contentType,
    compensation,
    compensationType,
  } = data;
  let summary = "";
  let contentText = "";

  switch (contentType) {
    case "instagramStory":
      contentText = "story";
      break;
    case "ugcPicture":
      contentText = "UGC Picture";
      break;
    case "ugcVideo":
      contentText = "UGC Video";
      break;
    case "tiktokVideo":
      contentText = "video";
      break;
    case "instagramReel":
      contentText = "reel";
      break;
    case "instagramPost":
      contentText = "picture";
      break;
    default:
      break;
  }

  if (contentType === "ugcPicture") {
    summary = `${quantity} customers will have the opportunity to upload 1 picture of ${
      specificProduct?.trim()?.length > 0
        ? specificProduct
        : "(specific product)"
    } from ${accountName} to earn ${compensationType === "cash" ? "$" : ""}${
      compensation ? compensation : 0
    }${compensationType === "cashback" ? "%" : ""} cashback on their order.`;
  }

  if (contentType === "ugcVideo") {
    summary = `${quantity} customers will have the opportunity to upload 1 video of ${
      specificProduct?.trim()?.length > 0
        ? specificProduct
        : "(specific product)"
    } from ${accountName} to earn ${compensationType === "cash" ? "$" : ""}${
      compensation ? compensation : 0
    }${compensationType === "cashback" ? "%" : ""} cashback on their order.`;
  } else {
    if (specificProduct) {
      summary = `${quantity} customers will have the opportunity to post 
            1 picture of ${
              specificProduct?.trim()?.length > 0
                ? "" + specificProduct?.trim()
                : accountName
            } and tag @${instagramHandle} on their 
             ${contentTypeText(contentType)} to earn ${
        compensationType === "cash" ? "$" : ""
      }${compensation ? compensation : 0}${
        compensationType === "cashback" ? "%" : ""
      } cashback on their order.`;
    } else {
      summary = `${quantity} customers who have at least
            ${minimumFollowerCount} followers will have the opportunity to post 
            1 ${contentText} of ${accountName} and tag @${instagramHandle} on their 
             ${contentTypeText(contentType)} to earn ${
        compensationType === "cash" ? "$" : ""
      }${compensation ? compensation : 0}${
        compensationType === "cashback" ? "%" : ""
      } cashback on their order.`;
    }
  }
  return summary;
};

export const offerRequirementSummary = (data) => {
  const {
    contentText,
    specificProduct,
    accountName,
    instagramHandle,
    discountType,
    discountItem,
    minPurchaseAmount,
    isFreeReward,
    expirationLimit,
    minimumFollowerCount,
    percentOrCashOff,
    isPrerequisite,
    compensation,
    prerequisiteQuantity,
    prerequisiteName,
    hashtag,
  } = data;
  let summaryText = `Post an ${contentText} of ${
    specificProduct?.trim()?.length ? specificProduct?.trim() : accountName
  } tagging @${instagramHandle} ${
    hashtag?.length > 0 ? "and #" + hashtag : ""
  } in order to get `;

  const compensationstr =
    (percentOrCashOff === "Cash"
      ? "$" + (compensation || 0)
      : (compensation || 0) + "%") + " off";
  if (isPrerequisite) {
    if (!isFreeReward) {
      if (discountType === "Item Discount") {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " +
              discountItem?.trim() +
              " with a purchase of " +
              prerequisiteQuantity +
              " " +
              prerequisiteName?.trim()
            : "");
        if (minPurchaseAmount > 0) {
          summaryText =
            summaryText +
            " and with a minimum purchase of $" +
            minPurchaseAmount +
            ".";
        } else {
          summaryText = summaryText + ".";
        }
      } else {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " + discountItem?.trim()
            : " on your next total order with a purchase of " +
              prerequisiteQuantity +
              " " +
              prerequisiteName);
        if (minPurchaseAmount > 0) {
          summaryText =
            summaryText +
            " and with a minimum purchase of $" +
            minPurchaseAmount +
            ".";
        } else {
          summaryText = summaryText + ".";
        }
      }
    } else {
      summaryText =
        summaryText +
        (discountItem?.trim()?.length > 0
          ? " free " +
            discountItem?.trim() +
            " with a purchase of " +
            prerequisiteQuantity +
            " " +
            prerequisiteName?.trim() +
            ""
          : "");
      if (minPurchaseAmount > 0) {
        summaryText =
          summaryText +
          " and with a minimum purchase of $" +
          minPurchaseAmount +
          ".";
      } else {
        summaryText = summaryText + ".";
      }
    }
  } else {
    if (!isFreeReward) {
      if (discountType === "Item Discount") {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " + discountItem?.trim() + ""
            : "");
        if (minPurchaseAmount > 0) {
          summaryText =
            summaryText +
            " with a minimum purchase of $" +
            minPurchaseAmount +
            ".";
        } else {
          summaryText = summaryText + ".";
        }
      } else {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " + discountItem?.trim()
            : "");
        if (minPurchaseAmount > 0) {
          summaryText =
            summaryText +
            " with a minimum purchase of $" +
            minPurchaseAmount +
            ".";
        } else {
          summaryText = summaryText + " on your next total order.";
        }
      }
    } else {
      summaryText =
        summaryText +
        (discountItem?.trim()?.length > 0
          ? " free " + discountItem?.trim()
          : "");
      if (minPurchaseAmount > 0) {
        summaryText =
          summaryText +
          " with a minimum purchase of $" +
          minPurchaseAmount +
          ".";
      } else {
        summaryText = summaryText + " on your next total order.";
      }
    }
  }
  return summaryText;
};

export const crossConceptSummary = (data) => {
  const {
    contentText,
    specificProduct,
    accountName,
    instagramHandle,
    discountType,
    discountItem,
    minPurchaseAmount,
    isFreeReward,
    expirationLimit,
    minimumFollowerCount,
    percentOrCashOff,
    isPrerequisite,
    compensation,
    prerequisiteQuantity,
    prerequisiteName,
    hashtag,
  } = data;
  let summaryText = `Post an ${contentText} of ${
    specificProduct?.trim()?.length ? specificProduct?.trim() : accountName
  } tagging @${instagramHandle} ${
    hashtag?.length > 0 ? "and #" + hashtag : ""
  } in order to get `;

  const compensationstr =
    (percentOrCashOff === "Cash"
      ? "$" + (compensation || 0)
      : (compensation || 0) + "%") + " off";
  if (isPrerequisite) {
    if (!isFreeReward) {
      if (discountType === "Item Discount") {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " +
              discountItem?.trim() +
              " with a purchase of " +
              prerequisiteQuantity +
              " " +
              prerequisiteName?.trim()
            : "");
        if (minPurchaseAmount > 0) {
          summaryText =
            summaryText +
            " and with a minimum purchase of $" +
            minPurchaseAmount +
            ".";
        } else {
          summaryText = summaryText + ".";
        }
      } else {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " + discountItem?.trim()
            : " on your next total order with a purchase of " +
              prerequisiteQuantity +
              " " +
              prerequisiteName);
        if (minPurchaseAmount > 0) {
          summaryText =
            summaryText +
            " and with a minimum purchase of $" +
            minPurchaseAmount +
            ".";
        } else {
          summaryText = summaryText + ".";
        }
      }
    } else {
      summaryText =
        summaryText +
        (discountItem?.trim()?.length > 0
          ? " free " +
            discountItem?.trim() +
            " with a purchase of " +
            prerequisiteQuantity +
            " " +
            prerequisiteName?.trim() +
            ""
          : "");
      if (minPurchaseAmount > 0) {
        summaryText =
          summaryText +
          " and with a minimum purchase of $" +
          minPurchaseAmount +
          ".";
      } else {
        summaryText = summaryText + ".";
      }
    }
  } else {
    if (!isFreeReward) {
      if (discountType === "Item Discount") {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " + discountItem?.trim() + ""
            : "");
        if (minPurchaseAmount > 0) {
          summaryText =
            summaryText +
            " with a minimum purchase of $" +
            minPurchaseAmount +
            ".";
        } else {
          summaryText = summaryText + ".";
        }
      } else {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " + discountItem?.trim()
            : "");
        if (minPurchaseAmount > 0) {
          summaryText =
            summaryText +
            " with a minimum purchase of $" +
            minPurchaseAmount +
            ".";
        } else {
          summaryText = summaryText + " on your next total order.";
        }
      }
    } else {
      summaryText =
        summaryText +
        (discountItem?.trim()?.length > 0
          ? " free " + discountItem?.trim()
          : "");
      if (minPurchaseAmount > 0) {
        summaryText =
          summaryText +
          " with a minimum purchase of $" +
          minPurchaseAmount +
          ".";
      } else {
        summaryText = summaryText + " on your next total order.";
      }
    }
  }
  return summaryText;
};

export const itemDiscountSummary = (data) => {
  const {
    contentText,
    specificProduct,
    accountName,
    instagramHandle,
    discountType,
    discountItem,
    minPurchaseAmount,
    isFreeReward,
    expirationLimit,
    minimumFollowerCount,
    percentOrCashOff,
    isPrerequisite,
    compensation,
    prerequisiteQuantity,
    prerequisiteName,
    hashtag,
    isEvergreenCode,
    ecommerceDiscountCode,
  } = data;
  let summaryText = `Post an ${contentText} of ${
    specificProduct?.trim()?.length ? specificProduct?.trim() : accountName
  } tagging @${instagramHandle} ${
    hashtag?.length > 0 ? "and #" + hashtag : ""
  } in order to get `;

  const compensationstr =
    (percentOrCashOff === "Cash"
      ? "$" + (compensation || 0)
      : (compensation || 0) + "%") + " off";
  if (isPrerequisite) {
    if (!isFreeReward) {
      if (discountType === "Item Discount") {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " +
              discountItem?.trim() +
              " with a purchase of " +
              prerequisiteQuantity +
              " " +
              prerequisiteName?.trim() +
              "."
            : " with a purchase of " +
              prerequisiteQuantity +
              " " +
              prerequisiteName?.trim() +
              ".");
      } else {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " + discountItem?.trim()
            : " on your next total order with a purchase of " +
              prerequisiteQuantity +
              " " +
              prerequisiteName +
              ".");
      }
    } else {
      summaryText =
        summaryText +
        (discountItem?.trim()?.length > 0
          ? " free " +
            discountItem?.trim() +
            " with a purchase of " +
            prerequisiteQuantity +
            " " +
            prerequisiteName?.trim() +
            ""
          : "");
    }
  } else {
    if (!isFreeReward) {
      if (discountType === "Item Discount") {
        summaryText =
          summaryText +
          compensationstr +
          (discountItem?.trim()?.length > 0
            ? " on " + discountItem?.trim() + "."
            : "");
      } else {
        summaryText =
          summaryText + compensationstr + " on your next total order.";
      }
    } else {
      summaryText =
        summaryText +
        (discountItem?.trim()?.length > 0
          ? " free " + discountItem?.trim() + "."
          : "");
    }
  }

  if (isEvergreenCode) {
    summaryText =
      summaryText +
      `${
        !discountItem && discountType === "Item Discount" ? "." : ""
      } Code ${ecommerceDiscountCode} will be distributed to the people who complete the offer.`;
  }
  return summaryText;
};

export const checkForDisable = (isEditing, inputValues, offerDetail) => {
  if (isEditing) {
    if (inputValues.isCustomerOffer) {
      /**
       * disable when
       * integration is there
       * if previous it evergreen and curren also evergreen
       * if previous no evergreen , no batchcode but current its evergreen
       * if previous its batchcode and current its evergreen
       */
      // need to discuss with client we need to disable while changing from batchCode to evergreen code (offerDetail.isUploadCodes && inputValues.isEvergreenCode)
      return (
        inputValues.integration?.length > 0 ||
        (!offerDetail?.isUploadCodes && inputValues?.isEvergreenCode) ||
        (offerDetail?.isEvergreenCode && inputValues?.isEvergreenCode)
      );
    } else if (inputValues?.isPrivate) {
      // handle for private logic
      return (
        inputValues.integration?.length > 0 ||
        (!offerDetail?.isUploadCodes && inputValues?.isEvergreenCode) ||
        (offerDetail?.isEvergreenCode && inputValues?.isEvergreenCode)
      );
    } else {
      // handle for influencer logic
      return (
        inputValues.integration?.length > 0 ||
        (!offerDetail?.isUploadCodes && inputValues?.isEvergreenCode) ||
        (offerDetail?.isEvergreenCode && inputValues?.isEvergreenCode)
      );
    }
  } else {
    // bypass in create offer flow
    return false;
  }
};

const checkQuantity = (quantity, offerDetail, inputValues) => {
  let totalQuantity = 0;
  let numberOfferLeft = 0;
  /**
   * Quantity & NOO (number of offers) logic:
   * Yes, with the current number of offers left (NOT THE QUANTITY),
   * and then they can increase this number which will update the numberOffersLeft key and if the current quantity < new edited value entered, then the quantity =  new edited value entered in DB.
   * Ex. Increasing offer : Quantity is 10, there are 6 offers left, while editing, the number of offers field says 6. The client edits it to 20. First, numberOffersLeft gets changed to 20. Then, because quantity (10) is < new entered value (20), then quantity gets changed to 20 in the DB :)
   * Ex. Decreasing: Quantity is 10, there are 6 offers left, while editing, the number of offers field says 6. The client edits it to 4. numberOffersLeft gets changed to 4. quantity does NOT change in value (stays at 10).
   */
  if (quantity > offerDetail?.quantity) {
    numberOfferLeft = quantity;
    totalQuantity = quantity;
  } else if (quantity < offerDetail?.quantity) {
    totalQuantity = offerDetail?.quantity;
    numberOfferLeft = quantity;
  } else {
    totalQuantity = offerDetail?.quantity;
    numberOfferLeft = offerDetail?.numberOffersLeft;
  }

  return { totalQuantity, numberOfferLeft };
};

const checkQtyForCustomerOffer = (offerDetail, inputValues) => {
  const { isEvergreenCode, isUploadCodes, integration } = inputValues;

  /**
   * use case 1
   * No batch code, no integration, no evergreen code (in-person redemption)
   * No problems with any editing features
   * Uses the quantity & NOO logic
   */

  if (
    !integration?.length &&
    !isEvergreenCode &&
    !isUploadCodes &&
    !offerDetail?.isEvergreenCode &&
    !offerDetail?.isUploadCodes
  ) {
    console.log("Customer offer case#3 executed....");
    return checkQuantity(inputValues.quantity, offerDetail, inputValues);
  }

  /**
   * Use case 3 : Has integration
   * Can edit quantity
   * Cannot edit compensation type (percent or cash) NOR amount
   * Should hide integration page
   * Uses the quantity & NOO logic
   */
  if (integration?.length && !isEvergreenCode && !isUploadCodes) {
    console.log("Customer offer case#3 executed....");
    return checkQuantity(inputValues.quantity, offerDetail, inputValues);
  }

  /**
   * Use case 4 : Evergreen code
   * Can edit quantity
   * Cannot edit compensation type (percent or cash) NOR amount
   * Uses quantity & NOO logic
   * not evergreen intially and no batch code but while adding if evergreen
   */

  if (
    isEvergreenCode &&
    !offerDetail?.isEvergreenCode &&
    !offerDetail?.isUploadCodes &&
    !isUploadCodes
  ) {
    console.log("Customer offer case#4 executed....");
    return checkQuantity(inputValues?.quantity, offerDetail, inputValues);
  }
  if (isEvergreenCode && offerDetail.isEvergreenCode) {
    console.log("Customer offer case#4.5 executed....");
    return checkQuantity(inputValues?.quantity, offerDetail, inputValues);
  }

  /**
   * use case 2 : batch code
   * Cannot delete previous batch codes
   * Can upload more batch codes
   * Quantity/numberOffersLeft is hidden → goes hand in hand with total number of codes uploaded
   * uses the quantity & NOO logic
   */
  if (isUploadCodes && offerDetail?.isUploadCodes) {
    console.log("Customer offer case#2 executed....");
    return {
      ...checkQuantity(
        inputValues?.batchCodes?.length + offerDetail.batchCodes?.length,
        offerDetail,
        inputValues
      ),
      addCodes: inputValues?.batchCodes?.length ? true : false,
    };
  }

  /**
   * No batch code ==> batchcode but no evergreen
   */
  if (
    !offerDetail?.isEvergreenCode &&
    !isEvergreenCode &&
    isUploadCodes &&
    !offerDetail?.isUploadCodes
  ) {
    console.log("Customer offer case#2.5 executed....");
    return {
      totalQuantity: inputValues.batchCodes?.length,
      numberOfferLeft: inputValues?.batchCodes?.length,
      addCodes: true,
    };
  }

  /**
   * use case 5
   * Batch Code Offer → Changes to no batch code & no evergreen
   * This means it would be a offer for in-person redemption
   * So there should be no problem editing features
   * We delete the batch code file from the batchCodes collection
   * Update edited fields, turn isUploadCodes to false
   */

  if (offerDetail?.isUploadCodes && !isUploadCodes && !isEvergreenCode) {
    console.log("Customer offer case#5 executed....");
    return {
      removeCodes: true,
      ...checkQuantity(inputValues?.quantity, offerDetail, inputValues),
    };
  }
  /**
   * Batch Code Offer → Evergreen Offer
   * Would show the number of offers screen with nothing in the text field
   *  → would overwrite quantity and numberOffersLeft with new inputted value
   * ecommerceDiscountCode = the inputted evergreen code
   * is Evergreen → true
   * isUploadCodes → false
   */
  if (offerDetail?.isUploadCodes && isEvergreenCode) {
    console.log("Customer offer case#6 executed....");
    return {
      totalQuantity: inputValues?.quantity,
      numberOfferLeft: inputValues?.quantity,
      removeCodes: true,
    };
  }
  /**
   * Evergreen Offer → Batch Code Offer
   * Upload codes (with ability to delete)
   * Make batchcode file in batchCodes collection
   * is Evergreen → false
   * isUploadCodes → true
   * quantity = # of batch codes uploaded
   * numberOffersLeft = # of batch codes uploaded
   */
  if (offerDetail?.isEvergreenCode && isUploadCodes) {
    console.log("Customer offer case#7 executed....");
    return {
      totalQuantity: inputValues?.batchCodes?.length,
      numberOfferLeft: inputValues?.batchCodes?.length,
      addCodes: true,
    };
  }
  console.log("Customer offer default case executed....");
  return checkQuantity(inputValues?.quantity, offerDetail, inputValues);
};

const checkQtyForPrivateOffer = (offerDetail, inputValues) => {
  const { isEvergreenCode, isUploadCodes, integration } = inputValues;
  /**
   * use case 1
   * No batch code, no integration, no evergreen code (in-person redemption)
   * No problems with any editing features
   * Uses
   */

  if (
    !integration?.length &&
    !isEvergreenCode &&
    !isUploadCodes &&
    !offerDetail?.isEvergreenCode &&
    !offerDetail?.isUploadCodes
  ) {
    console.log("Private offer case#1 executed....");
    return checkQuantity(
      inputValues?.privateOfferPhoneList?.length,
      offerDetail,
      inputValues
    );
  }

  /**
   * use case 3 --> Integration
   * Cannot edit compensation type (percent or cash) NOR amount (because of the shopify/bigcommerce rule)
   * If adding more customer(s):
   * numberOffersLeft = number of total customers
   * quantity = number of total customers
   * Send texts to newly added customers
   */
  if (integration?.length && !isEvergreenCode && !isUploadCodes) {
    console.log("Private offer case#3 executed....");
    return checkQuantity(
      inputValues?.privateOfferPhoneList?.length,
      offerDetail,
      inputValues
    );
  }

  /**
   * use case 2 : Private Batch Code 
   * Add more batch codes to initial number of codes
   * If adding more customer(s) to private offer:
     *  --> MAKE SURE that number of total codes >= number of total customers
     * ---> Send texts to newly added customers
   * numberOffersLeft = number of total codes 
   * Quantity = number of total codes

  */
  if (isUploadCodes && offerDetail.isUploadCodes) {
    console.log("Private offer case#2 executed....");
    return {
      addCodes: inputValues?.batchCodes.length ? true : false,
      ...checkQuantity(
        inputValues?.batchCodes?.length + offerDetail?.batchCodes?.length,
        offerDetail,
        inputValues
      ),
    };
  }

  /**
   * usecase 2.5
   * batch code --> change to no batch code
   */
  if (offerDetail?.isUploadCodes && !isUploadCodes && !isEvergreenCode) {
    // need to cross verify in this case
    console.log("Private offer case#2.5 executed....");
    return {
      removeCodes: true,
      ...checkQuantity(
        inputValues?.privateOfferPhoneList,
        offerDetail,
        inputValues
      ),
    };
    // @todo
    // need to delete all batchCodes in this case
  }

  /**
   * No batch code ==> batchcode but no evergreen
   */
  if (
    !offerDetail?.isEvergreenCode &&
    !isEvergreenCode &&
    isUploadCodes &&
    !offerDetail?.isUploadCodes
  ) {
    console.log("Private offer case#2.75 executed....");
    return {
      addCodes: true,
      ...checkQuantity(
        inputValues?.batchCodes?.length,
        offerDetail,
        inputValues
      ),
    };
  }

  /**
   * Private Evergreen Offer
   * Cannot edit compensation type (percent or cash) NOR amount
   * If adding more customer(s):
   * numberOffersLeft = number of total customers
   * quantity = number of total customers
   *Send texts to newly added customers
   */
  if (
    isEvergreenCode &&
    !offerDetail?.isEvergreenCode &&
    !offerDetail?.isUploadCodes &&
    !isUploadCodes
  ) {
    console.log("Private offer case#4 executed....");

    return checkQuantity(
      inputValues?.privateOfferPhoneList?.length,
      offerDetail,
      inputValues
    );
  }

  /**
   * if initially evergreen and still its evergreen
   */
  if (isEvergreenCode && offerDetail.isEvergreenCode) {
    console.log("Private offer case#4.5 executed....");
    return checkQuantity(
      inputValues?.privateOfferPhoneList?.length,
      offerDetail,
      inputValues
    );
  }

  /**
   * Private Evergreen Offer → Private Batch Code Offer
   * Upload codes (with ability to delete)
   * Make batchcode file in batchCodes collection
   * is Evergreen → false
   * isUploadCodes → true
   * Make sure that number of uploaded codes >= number of customers added
   * quantity = # of batch codes uploaded
   * numberOffersLeft = # of batch codes uploaded
   */
  if (offerDetail?.isEvergreenCode && isUploadCodes) {
    console.log("Private offer case#5 executed....");

    return {
      totalQuantity: inputValues?.batchCodes?.length,
      numberOfferLeft: inputValues?.batchCodes?.length,
      addCodes: true,
    };
  }

  /**
   * Private Batch Code Offer → Private Evergreen Offer
   * quantity = # of customers
   * numberOffersLeft = # of customers
   * ecommerceDiscountCode = the inputted evergreen code
   * is Evergreen → true
   * isUploadCodes → false
   */
  if (offerDetail?.isUploadCodes && isEvergreenCode) {
    console.log("Private offer case#6 executed....");
    return {
      totalQuantity: inputValues?.privateOfferPhoneList?.length,
      numberOfferLeft: inputValues?.privateOfferPhoneList?.length,
      removeCodes: true,
    };
  }

  console.log("Private offer default case executed....");

  return checkQuantity(
    inputValues?.privateOfferPhoneList?.length,
    offerDetail,
    inputValues
  );
};

const checkQtyForInfluencerOffer = (offerDetail, inputValues) => {
  const { isEvergreenCode, isUploadCodes, integration } = inputValues;

  /**
   * use case 1
   * No batch code, no integration, no evergreen code (in-person redemption)
   * No problems with any editing features
   * Uses
   */

  if (
    !integration?.length &&
    !isEvergreenCode &&
    !isUploadCodes &&
    !offerDetail?.isEvergreenCode &&
    !offerDetail?.isUploadCodes
  ) {
    console.log("Influencer offer case#1 executed....");
    return checkQuantity(
      inputValues?.influencerPhoneNumbers?.length,
      offerDetail,
      inputValues
    );
  }

  /**
   * use case 3 -->Influencer Integration
   * Cannot edit compensation type (percent or cash) NOR amount (because of the shopify/bigcommerce rule)
   * If adding more customer(s):
   * numberOffersLeft = number of total customers
   * quantity = number of total customers
   * Send texts to newly added customers
   */
  if (integration?.length && !isEvergreenCode && !isUploadCodes) {
    console.log("Influencer offer case#3 executed....");
    return checkQuantity(
      inputValues?.influencerPhoneNumbers?.length,
      offerDetail,
      inputValues
    );
  }

  /**
   * use case 2 : Influencer Batch Code
   * Add more batch codes to initial number of codes
   * If adding more influencer(s) to private offer:
   * MAKE SURE that number of total codes >= number of total influencers
   * Send texts to newly added influencers
   * numberOffersLeft = number of total codes
   * Quantity = number of total codes
   */
  if (isUploadCodes && offerDetail?.isUploadCodes) {
    console.log("Influencer offer case#2 executed....");
    return {
      ...checkQuantity(
        inputValues?.batchCodes.length + offerDetail?.batchCodes?.length,
        offerDetail,
        inputValues
      ),
      addCodes: inputValues.batchCodes.length ? true : false,
    };
  }

  if (offerDetail?.isUploadCodes && !isUploadCodes && !isEvergreenCode) {
    // need to cross verify in this case
    console.log("Influencer offer case#2.5 executed....");
    return {
      removeCodes: true,
      ...checkQuantity(
        inputValues?.influencerPhoneNumbers?.length,
        offerDetail,
        inputValues
      ),
    };
  }

  /**
   * No batch code ==> batchcode but no evergreen
   */
  if (
    !offerDetail?.isEvergreenCode &&
    !isEvergreenCode &&
    isUploadCodes &&
    !offerDetail?.isUploadCodes
  ) {
    console.log("Influencer offer case#2.75 executed....");
    return {
      totalQuantity: inputValues?.batchCodes?.length,
      numberOfferLeft: inputValues?.batchCodes?.length,
      addCodes: true,
    };
  }

  /**
   * Influencer  Evergreen Offer
   * Cannot edit compensation type (percent or cash) NOR amount
   * If adding more influencer(s):
   * numberOffersLeft = number of total influencers
   * quantity = number of total influencers
   * Send texts to newly added influencers
   */
  if (
    isEvergreenCode &&
    !offerDetail?.isEvergreenCode &&
    !offerDetail?.isUploadCodes &&
    !isUploadCodes
  ) {
    console.log("Influencer offer case#4 executed....");
    return checkQuantity(
      inputValues?.influencerPhoneNumbers?.length,
      offerDetail,
      inputValues
    );
  }

  /**
   * if initially evergreen and still its evergreen
   */
  if (isEvergreenCode && offerDetail?.isEvergreenCode) {
    console.log("Influencer offer case#4.5 executed....");
    return checkQuantity(
      inputValues?.influencerPhoneNumbers?.length,
      offerDetail,
      inputValues
    );
  }

  /**
   * Influencer Evergreen Offer → Influencer Batch Code Offer
   * Upload codes (with ability to delete)
   * Make batchcode file in batchCodes collection
   * is Evergreen → false
   * isUploadCodes → true
   * Make sure that number of uploaded codes >= number of influencers added
   * quantity = # of batch codes uploaded
   * numberOffersLeft = # of batch codes uploaded
   */
  if (offerDetail?.isEvergreenCode && isUploadCodes) {
    console.log("Influencer offer case#5 executed....");
    return {
      totalQuantity: inputValues?.batchCodes?.length,
      numberOfferLeft: inputValues?.batchCodes?.length,
      allCodes: true,
    };
  }

  /**
   * Influencer Batch Code Offer → Influencer Evergreen Offer
   * quantity =  # of influencers
   * numberOffersLeft =  # of influencers
   * ecommerceDiscountCode = the inputted evergreen code
   * is Evergreen → true
   * isUploadCodes → false
   */
  if (offerDetail?.isUploadCodes && isEvergreenCode) {
    console.log("Influencer offer case#6 executed....");
    return {
      totalQuantity: inputValues?.influencerPhoneNumbers?.length,
      numberOfferLeft: inputValues?.influencerPhoneNumbers?.length,
      removeCodes: true,
    };
  }

  return checkQuantity(
    inputValues?.influencerPhoneNumbers?.length,
    offerDetail,
    inputValues
  );
};

export const validateEditStats = (inputValues, offerDetail) => {
  if (inputValues.isCustomerOffer) {
    return checkQtyForCustomerOffer(offerDetail, inputValues);
  }
  if (inputValues.isPrivate) {
    return checkQtyForPrivateOffer(offerDetail, inputValues);
  }
  if (inputValues.isInfluencerOffer) {
    return checkQtyForInfluencerOffer(offerDetail, inputValues);
  }
  return inputValues.quantity;
};

export const validateEditStatsForCash = (inputValues, offerDetail) => {
  if (inputValues?.isCustomerOffer) {
    // directly add quantity if it is changing else same
    return {
      quantity: inputValues?.quantity,
      numberOfferLeft:
        inputValues?.quantity > offerDetail?.quantity
          ? inputValues?.quantity
          : offerDetail?.numberOfferLeft,
    };
  }
  if (inputValues.isPrivate) {
    // directly assign to private customer length to quantity if changing else same
    return {
      quantity: inputValues?.privateOfferPhoneList?.length,
      numberOfferLeft:
        inputValues?.privateOfferPhoneList?.length >
        offerDetail?.privateOfferPhoneList?.length
          ? inputValues?.privateOfferPhoneList?.length
          : offerDetail?.numberOfferLeft,
    };
  }
  if (inputValues?.isInfluencerOffer) {
    // directly assign to influencer customer length to quantity if changing else same
    return {
      quantity: inputValues?.influencerPhoneNumbers?.length,
      numberOfferLeft:
        inputValues?.influencerPhoneNumbers?.length >
        offerDetail?.influencerPhoneNumbers?.length
          ? inputValues?.influencerPhoneNumbers?.length
          : offerDetail?.numberOfferLeft,
    };
  }
};

export const validateEditStatsForCashback = (inputValues, offerDetail) => {};
