/* eslint-disable */
// Chakra Imports
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import Environment from 'environment';

export default function AdminNavbar(props: {
	secondary: boolean;
	pageTitle: string;
	fixed: boolean;
	onOpen: (...args: any[]) => any;
}) {
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	const { secondary, pageTitle } = props;

	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	

	return (
		<Box
			boxShadow='none'
			bg={useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)')}
			borderColor='transparent'
			filter='none'
			backgroundPosition='center'
			backgroundColor="transparent"
			backgroundSize='cover'
			borderRadius='16px'
			borderWidth='1.5px'
			borderStyle='solid'
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }}
			display={secondary ? 'block' : 'flex'}
			minH='75px'
			justifyContent={{ xl: 'center' }}
			lineHeight='25.6px'
			mx='auto'
			mt='0px'
			mb="0px"
			right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
			px={{
				sm: '15px',
				md: '10px'
			}}
			ps={{
				xl: '12px'
			}}
			pt='8px'
			top={{ base: '12px', md: '16px', xl: '18px' }}
			w={{
				base: 'calc(100vw - 6%)',
				md: 'calc(100vw - 8%)',
				lg: 'calc(100vw - 6%)',
				xl: 'calc(100vw - 350px)',
				'2xl': 'calc(100vw - 365px)'
			}}>
			{Environment.USE_TEST_MODE && (<Text>*Running in test mode*</Text>)}
			<Box ms='auto' w={{ sm: '100%', md: 'unset' }} mb="0px">
				<AdminNavbarLinks
					onOpen={props.onOpen}
					secondary={props.secondary}
					fixed={props.fixed}
				/>
			</Box>
		</Box>
	);
}
