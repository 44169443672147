import { Box, Flex, Spinner, useToast, Text, Button } from "@chakra-ui/react";
import Environment from "environment";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import exchangeAccessToken, {
  checkForAssociateUsers,
  getInstagramDetails,
  saveFBMetaInformation,
  updateFBUserDetails,
} from "services/firebaseService";
import { checkTokenForUser } from "services/flaskService";
import { decryptKey } from "utils";
import { auth } from "../../../firebase";
import AutoEngagementPreview from "./components/AutoengagementPreview";

import { Facebook } from "components/icons/Icons";

const AutoEngagement = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  const [user] = useAuthState(auth);
  // Replace with your Facebook App ID
  const appId = decryptKey(Environment.FACEBOOK_APP_ID);

  useEffect(() => {
    // console.log("hello im in user ---");
    // Load the Facebook SDK asynchronously
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      // if (d.getElementById(id)) return;
      if (d.getElementById(id)) {
        checkToken();
        // setIsLoading(false);
        return;
      }
      const js: any = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: "v17.0",
      });
      checkToken();
    };

    // Initialize the SDK once it's loaded
  }, []);

  const handleLogout = async () => {
    try {
      window.FB.getLoginStatus(async function (response) {
        if (response.status === "connected") {
          try {
            window.FB.logout(function () {
              setIsLoggedIn(false);
              setUserData(null);
              updateFBUserDetails(user?.uid);
            });
          } catch (error) {
            console.log("Error from logout status check=", error);
            setIsLoggedIn(false);
            setUserData(null);
            updateFBUserDetails(user?.uid);
          }
        } else {
          setIsLoggedIn(false);
          setUserData(null);
          updateFBUserDetails(user?.uid);
        }
      });
    } catch (error) {
      console.error("Error with FB.getLoginStatus:", error);
      setIsLoggedIn(false);
      setUserData(null);
      // updateFBUserDetails(user?.uid);
      setIsLoading(false);
    }
  };

  const checkToken = async () => {
    setIsLoading(true); // Start loading when checking login status
    try {
      // Check if a token exists for the current user in your backend
      const tokenResponse = await checkTokenForUser(user?.uid);
      if (tokenResponse && tokenResponse?.data && tokenResponse.data?.isLogin) {
        setIsLoggedIn(true);
        setUserData(tokenResponse?.data);
      } else {
        toast({
          title: "Validation Errors",
          description: "You must login first.",
          status: "error",
        });
        setIsLoggedIn(false);
      }
      setIsLoading(false); // End loading after the operation is complete
    } catch (error) {
      console.error("Error checking token:", error);
      setIsLoading(false); // End loading after the operation is complete
      setIsLoggedIn(false);
      handleLogout();
      // toast({
      //   title: "Validation Errors",
      //   description: "Error occurred while checking token.",
      //   status: "error",
      // });
    }
  };

  const saveUserInfo = async (userInfo) => {
    try {
      setIsLoading(true);
      let userData = userInfo;
      const userDetails: any = await checkForAssociateUsers(userInfo?.fbUserId);
      const getCurrentUser = userDetails?.find(
        (usr) =>
          usr?.fbUserId === userInfo?.fbUserId && usr.userId === user?.uid
      );
      if (!getCurrentUser && userDetails?.length) {
        toast({
          title: "Validation Errors",
          description:
            "User is associated with another account please try with another account.",
          status: "error",
        });
        setIsLoading(false);
        return;
      }
      const refreshTokenResponse = await exchangeAccessToken(
        userInfo?.meta.accessToken
      );
      let isLogin = false;
      if (refreshTokenResponse) {
        const instagramDetail = await getInstagramDetails(
          refreshTokenResponse?.access_token
        );
        isLogin = true;
        userData = {
          ...userInfo,
          pageId: instagramDetail?.pageId || "",
          pageName: instagramDetail?.pageName || "",
          pageToken: instagramDetail?.pageToken || "",
          instagramId: instagramDetail?.instagramId || "",
          instagramUser: instagramDetail?.instagramUser || "",
          instagramUserName: instagramDetail?.instagramUserName || "",
          isLogin,
          meta: {
            ...userInfo?.meta,
            accessToken: refreshTokenResponse?.access_token,
            expiresIn: refreshTokenResponse?.expires_in || 0,
            isTokenRefreshed: true,
          },
        };
      } else {
        // throw Error("Error occured while login with facebook.");
        userData = {
          ...userInfo,
          pageId: "",
          pageName: "",
          pageToken: "",
          instagramId: "",
          instagramUser: "",
          instagramUserName: "",
          isLogin,
          meta: {
            ...userInfo?.meta,
            isTokenRefreshed: false,
          },
        };
      }
      await saveFBMetaInformation(user?.uid, userData);
      // setUserData(userInfo);
      setIsLoggedIn(isLogin);
      await checkToken();
    } catch (errr: any) {
      toast({
        title: "Validation Errors",
        description: "User cancelled login or did not fully authorize.",
        status: "error",
      });
      setIsLoading(false);
    }
  };

  const fetchUserData = async (metaData) => {
    window.FB.api(
      "/me",
      { fields: "name,email,account" },
      async function (response: any) {
        const { authResponse = null } = metaData;
        const sampleDMLogs: any[] = [];
        const metaClone = {
          dmTemplate: "",
          dmLogs: sampleDMLogs,
          extraInfo: {},
          fbUserId: authResponse?.userID || "",
          dmEnable: false,
          meta: {
            fbUserId: authResponse?.userID || "",
            userName: response?.name || "",
            expiresIn: authResponse?.expiresIn || 0,
            accessToken: authResponse?.accessToken || "",
            grantedScopes: authResponse?.grantedScopes || "",
            dataAccessExpirationTime:
              authResponse?.data_access_expiration_time || "",
          },
          isDeleted: false,
        };
        saveUserInfo(metaClone);
      }
    );
  };

  const handleLogin = () => {
    window.FB.login(
      function (response: any) {
        if (response.authResponse) {
          // setIsLoggedIn(true);
          setIsLoading(true);
          fetchUserData(response);
        } else {
          toast({
            title: "Validation Errors",
            description: "User cancelled login or did not fully authorize.",
            status: "error",
          });
          // console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "pages_show_list,pages_manage_metadata,pages_messaging,instagram_basic,pages_manage_engagement,pages_manage_metadata",
        return_scopes: true,
      }
    );
  };

  if (isLoading) {
    return (
      <Box>
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      </Box>
    );
  }

  return (
    <>
      {!isLoggedIn ? (
        <Flex
          overflow="hidden"
          backgroundColor="white"
          borderRadius="10px"
          width="100%"
          position="relative"
          height="calc(100vh - 135px)"
          padding={"20px 30px 30px"}
          flexDirection={"column"}
        >
          <Text
            fontWeight="bold"
            fontSize="25px"
            borderBottom="1.3px solid #E2E8F0"
            paddingBottom={"15px"}
          >
            AutoEngagement
          </Text>
          <Flex alignItems="center" justifyContent="center" padding="50px 0px">
            <Button
              variant="chakra_btn_facebook"
              size="chakra_md"
              borderRadius="5px"
              transition="transform 0.2s, box-shadow 0.2s"
              transform="translateY(0)"
              boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
              tabIndex={-1}
              onClick={handleLogin}
            >
              <Facebook h="24px" w="24px" marginRight="10px" /> Login with
              Facebook
            </Button>
          </Flex>
        </Flex>
      ) : (
        <div>
          {/* <h3>Welcome, {userData && userData.name}</h3>
          <button onClick={handleLogout}>Logout</button> */}
          <AutoEngagementPreview
            metaData={userData}
            userId={user?.uid}
            handleLogout={handleLogout}
          />
        </div>
      )}
    </>
  );
};

export default AutoEngagement;
