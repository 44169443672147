import React, { useContext } from 'react';
import { Box, Text, Flex, VStack, HStack, Icon, SimpleGrid } from '@chakra-ui/react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { InfluencerCampaignsDataContext } from 'contexts/influencerCampaignsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { Influencer, InfluencerCampaign, CollaborationsData } from '../types';

interface AnalyticsProps {
  clientID: string;
}

const Analytics: React.FC<AnalyticsProps> = ({ clientID }) => {
  const influencerCampaigns = useContext(InfluencerCampaignsDataContext)[clientID] as Record<string, InfluencerCampaign>;
  const influencerData = useContext(InfluencersDataContext) as Record<string, Influencer>;

  const getActiveCreators = (influencerCampaign: InfluencerCampaign) => {
   
    return Object.values(influencerCampaign.collaborations).length;
  };

  const getTotalPosts = (influencerCampaign: InfluencerCampaign) => {
    if (!influencerCampaign.collaborations) return 0;
  
    return Object.values(influencerCampaign.collaborations).reduce((total, collab) => {
      const { instagramPosts, instagramReels, instagramStories, tiktoks } = collab.deliverableCounts;
      return total + instagramPosts + instagramReels + instagramStories + tiktoks;
    }, 0);
  };

  const totalCreators = Object.values(influencerCampaigns || {}).reduce((total: number, campaign) => {
      return total + getActiveCreators(campaign);
    }, 0);

  const totalPosts = Object.values(influencerCampaigns || {}).reduce((totalPosts: number, campaign) => {
      return totalPosts + getTotalPosts(influencerCampaigns[campaign.id]);
  }, 0);

  const formatFollowersCount = (followers: number): string => {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + "k";
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + "M";
    }
    return followers.toString();
  }

  const getTotalReach = () => {

    const relevantCollaborations = Object.values(influencerCampaigns?.collaborations || {}).filter(
      collab => collab.status === 'collabCompleted'
    );

    const influencerIds = new Set(relevantCollaborations.map(collab => collab.influencerID));
    const totalFollowers = Array.from(influencerIds).reduce((total, influencerID) => {
      const influencer = influencerData[influencerID];
      return total + (influencer ? influencer.follower_count : 0);
    }, 0);

    return formatFollowersCount(totalFollowers);
  };

  const calculateCompletionRate = () => {
    const totalCollaborations = Object.values(influencerCampaigns?.collaborations || {}).length;
    const completedCollaborations = Object.values(influencerCampaigns?.collaborations || {}).filter(
      collab => collab.status === 'collabCompleted'
    ).length;
    
    return totalCollaborations > 0 ? Math.round((completedCollaborations / totalCollaborations) * 100) : 0;
  };

  const totalReach = getTotalReach();
  const completionRate = calculateCompletionRate().toString() + '%';

  const boxContents = [
    { 
      label: 'Collabs', 
      value: totalCreators.toString(), 
      completed: Object.values(influencerCampaigns?.collaborations || {}).filter(
        collab => collab.status === 'collabCompleted'
      ).length.toString() 
    },
    { label: 'Total Posts', value: totalPosts.toString(), trend: totalPosts > 0 ? 'up' : undefined, trendValue: totalPosts.toString() },
    { label: 'Estimated Reach', value: totalReach, trend: totalReach !== '0' ? 'up' : undefined, trendValue: totalReach },
    { label: 'Completion Rate', value: completionRate, trend: completionRate !== '0%' ? 'up' : undefined, trendValue: completionRate },
  ];

  return (
    <Box width="100%" maxWidth="1200px" mx="auto" px={4}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={4} mt={4}>
        {boxContents.map(({ label, value, completed, trend, trendValue }, index) => (
          <Box
            key={index}
            bg="white"
            borderRadius="md"
            boxShadow="md"
            p={4}
            position="relative"
            height={{ base: '120px', md: '140px' }}
          >
            <VStack align="flex-start" spacing={2} height="100%">
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                fontWeight="bold"
                color="gray.700"
              >
                {label}
              </Text>
              <VStack align="flex-start" spacing={0} mt="auto">
                <HStack spacing={2} alignItems="baseline">
                  <Text
                    fontSize={{ base: '2xl', md: '3xl' }}
                    fontWeight="bold"
                    color="black"
                  >
                    {value}
                  </Text>
                  <HStack spacing={1} alignItems="center">
                    {trend && (
                      <>
                        <Icon
                          as={trend === 'up' ? FaArrowUp : FaArrowDown}
                          color={trend === 'up' ? 'green.500' : 'red.500'}
                          w={{ base: 2, md: 3 }}
                          h={{ base: 2, md: 3 }}
                        />
                        <Text
                          fontSize={{ base: 'xs', md: 'sm' }}
                          fontWeight="bold"
                          color={trend === 'up' ? 'green.500' : 'red.500'}
                        >
                          {trendValue}
                        </Text>
                      </>
                    )}
                  </HStack>
                </HStack>
              </VStack>
            </VStack>
            {completed && (
              <Text 
                fontSize={{ base: 'xs', md: 'sm' }}
                color="gray.500" 
                position="absolute"
                bottom="5px"
                left="16px"
              >
                <Text as="span" fontWeight="bold">{completed}</Text> completed
              </Text>
            )}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Analytics;