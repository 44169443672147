import React, {  useState, useContext } from 'react';
import { Box, Flex, Text, IconButton } from '@chakra-ui/react';
import { FaInstagram, FaEnvelope, FaTiktok, FaStar } from 'react-icons/fa';
import InstagramProfilePic from './InstagramProfilePic';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { Influencer } from '../types';
interface FeaturedInfluencersProps {
  //influencers: Record<string, Influencer> | null;
}

const FeaturedInfluencers: React.FC<FeaturedInfluencersProps> = ({  }) => {
  const influencers : Record<string, Influencer> | null = useContext(InfluencersDataContext);

  
  const influencersArray = Object.values(influencers).filter(influencer => influencer.featured);
  const featuredInfluencers = influencersArray.sort(() => Math.random() - 0.5);
  
  const formatFollowersCount = (followers: number): string => {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + 'k';
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + 'M';
    }
    return followers.toString();
  };

  return (
    <Flex
      overflowX="auto"
      p={4}
      alignItems="flex-start"
      gap={10}
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }} // Hide scrollbar for a cleaner look
      maxWidth="89%"
      mx="auto"
      ml={8} // Add left margin to shrink on the left side
    >
      {featuredInfluencers.map(influencer => (
        <Box
          key={influencer.id}
          bg="white"
          borderRadius="md"
          boxShadow="md"
          pt={5}
          px={5}
          textAlign="center"
          width="400"
          height="300"
          flexDirection="column"
          gap={4}
        >
          <Flex direction="column" gap={2} alignItems="center" justifyContent="center">
            <InstagramProfilePic profile_pic_url={influencer.profile_pic_url}
            instagramHandle={influencer.instagramHandle}
            imageSize="120px" />
            <Flex alignItems="center" direction="row" justifyContent="center" gap={4}>
              <Text fontSize="22" fontWeight="normal" color="black">
                {influencer.instagramHandle}
              </Text>
              <FaStar color="#0080FE" size={22} />
            </Flex>
            <Flex justifyContent="center" gap={6}>
              <IconButton icon={<FaEnvelope size={22} />} aria-label="Email" variant="ghost" colorScheme="gray" />
              <IconButton icon={<FaInstagram size={22} />} aria-label="Instagram" variant="ghost" colorScheme="gray" />
              <IconButton icon={<FaTiktok size={22} />} aria-label="TikTok" variant="ghost" colorScheme="gray" />
            </Flex>
            <Text color="gray.500" fontSize={18}>
              {formatFollowersCount(influencer.follower_count)} followers
            </Text>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
};

export default FeaturedInfluencers;
