import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Select,
  Spinner,
  Icon,
  Stack,
  Checkbox
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa6";
import { AccountsDataContext } from "contexts/accountsDataContext";
import { InfluencersDataContext } from "contexts/influencersDataContext";
import AccountsDropdown from "../../components/accountsDropDown";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import InfluencersList from './components/InfluencersList'
import Pagination from './components/Pagination';
import { useLocation, useHistory } from "react-router-dom";
import { setCampaignInfluencers, createCollaboration, getDocFromCollection } from "services/firebaseService";
import { getDocFromCache } from "firebase/firestore";
import { InfluencerCampaignsDataContext } from "contexts/influencerCampaignsDataContext";
import Environment from "environment";
import { Influencer, CollaborationsData, InfluencerCampaign } from "../../types";




export default function Search() {
  const accountsData = useContext(AccountsDataContext);
  const influencerCampaigns = useContext(InfluencerCampaignsDataContext) as Record<string, any>;
  const influencerData = useContext(InfluencersDataContext) as Record<string, any>; // Use the influencerData context


  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  
  const client = accountsData[queryParams.get("clientID")];

  const campaignID = queryParams.get("campaignID");

  const influencers: Record<string, Influencer> = useContext(InfluencersDataContext);

  const [selectedAccountName, setSelectedAccountName] = useState<string>("");

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterSelected, setFilterSelected] = useState(false);
  const [filterFeatured, setFilterFeatured] = useState(false);
  const [followerRange, setFollowerRange] = useState("");

  const [visibleInfluencers, setVisibleInfluencers] = useState<Influencer[][]>([[]]);
  const [currPage, setCurrPage] = useState(0);

  const [selectedInfluencers, setSelectedInfluencers] = useState<Set<string>>(new Set());

  const toggleInfluencerSelected = (influencerID: string) => {
    if (client.remainingCollabRequests - totalCreators - selectedInfluencers.size <= 0 && !selectedInfluencers.has(influencerID)) {
      return;
    }
    setSelectedInfluencers(prevSelected => {
      const updatedSet = new Set(prevSelected);
      if (updatedSet.has(influencerID)) {
        updatedSet.delete(influencerID);
      } else {
        updatedSet.add(influencerID);
      }
      return updatedSet;
    });
  }

  useEffect(() => {
    const filteredInfluencers = getFilteredInfluencers();
    const influencersByPage = groupInfluencersByPage(filteredInfluencers ?? [], 20);
    setVisibleInfluencers(influencersByPage);
    setCurrPage(0);
  }, [influencers, searchTerm, selectedState, selectedCity, filterBookmarked, filterSelected, filterFeatured, followerRange, client.id]);

  const getFilteredInfluencers = () => {
    const filtered = Object.keys(influencers ?? {})
      .map(key => influencers[key])
      .filter(influencer => {
        if (campaignID && (!influencer.email_address || influencer.email_address.trim() === '')) {
          return false;
        }
        influencer.name = influencer.firstName + (influencer.lastName != "unknown" ? " " + influencer.lastName : "");
        const matchesSearchTerm =
          influencer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          influencer.email_address.toLowerCase().includes(searchTerm.toLowerCase()) ||
          influencer.instagramHandle.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesState = selectedState ? influencer.state === selectedState : true;
        const matchesCity = selectedCity ? influencer.city === selectedCity : true;
        const matchedBookmark = filterBookmarked ? client.bookmarkedInfluencers && client.bookmarkedInfluencers.includes(influencer.id) : true;
        const matchedSelected = filterSelected ? selectedInfluencers.has(influencer.id) : true;
        const matchedFeatured = filterFeatured ? influencer.featured == filterFeatured : true;
        const matchedFollowerRange = followerRanges[followerRange][0] <= influencer.follower_count && influencer.follower_count <= followerRanges[followerRange][1];
        return matchesSearchTerm && matchesState && matchesCity && matchedBookmark && matchedSelected && matchedFeatured && matchedFollowerRange;
      });
    return filtered.sort((a, b) => {
      return b.featured === a.featured ? 0 : b.featured ? 1 : -1;
    });
  }

  
  const getActiveCreators = (influencerCampaign: InfluencerCampaign) => {
    
    if (!influencerCampaign.collaborations) {
      console.log("No collaborations found.");
      return 0;
    }
    const activeInfluencers = new Set(
      Object.values(influencerCampaign.collaborations)
        .filter(collab => collab.influencerCampaignID === influencerCampaign.id)
        .map(collab => collab.influencerID)
    );
    
    return activeInfluencers.size;
  };

  const totalCreators = Object.values(influencerCampaigns).reduce((total: number, campaign) => {
    if (campaign.clientID === client.clientID) { // Filter by clientID
      return total + getActiveCreators(campaign);
    }
    return total; // Skip if not matching
  }, 0);


  const groupInfluencersByPage = (influencers, groupSize) => {
    let groups = [];
    for (let i = 0; i < influencers.length; i += groupSize) {
      groups.push(influencers.slice(i, i + groupSize));
    }
    if (groups.length == 0) {
      groups = [[]];
    }
    return groups;
  }

  const handleAccountNameChange = (name: string) => {
    setSelectedAccountName(name);
  };

  const uniqueStates = new Set<string>();
  const uniqueCities = new Set<string>();
  Object.values(influencers ?? {}).forEach((influencer) => {
    uniqueStates.add(influencer.state);
    if (selectedState === influencer.state) {
      if (influencer.city != "") {
        const cities = influencer.city.split(", ");
        cities.forEach((city) => {
          uniqueCities.add(city);
        });
      }
    }
  });

  const followerRanges = {
    "": [0, Infinity],
    "0 - 25k": [0, 25000],
    "25k - 100k": [25000, 100000],
    "100k - 500k": [100000, 500000],
    "500k+": [500000, Infinity]
  };

  const handleCampaignLaunched = async () => {
    const campaignData = await getDocFromCollection("influencerCampaigns", campaignID);
    console.log("Full campaign data:", campaignData);

    if (!campaignData) {
      console.error(`Campaign with ID ${campaignID} not found`);
      return;
    }

   

    for (const influencerID of selectedInfluencers) {
      if (client.remainingCollabRequests <= 0) {
        console.log("No more collab requests remaining. Stopping process.");
        break;
      }

      try {
        const influencer = influencers[influencerID];
        if (!influencer) {
          console.error(`Influencer with ID ${influencerID} not found`);
          continue;
        }
        
        await createCollaboration(
          `${influencerID}_${campaignID}`, // influencerId_campaignId
          influencerID,
          campaignID,
          campaignData['compensation'], 
          campaignData['rewardType'],
          campaignData['deliverableCounts'],
          {}, // deliverableLinks - this will be empty initially
          [], 
          "pendingInfluencerApproval" // status - set initial status as pending //create a new entry in the collabs table
        );
        console.log(`Collaboration created for influencer ${influencerID}`);
        
        client.remainingCollabRequests--;

        // make the api call to send the email to the influencer
        try {
          const response = await fetch(`${Environment.FLASK_HOST_URL}/send-influencer-collab-email/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': Environment.FLASK_API_KEY
            },
            body: JSON.stringify({
              email: influencer.email_address, // Use the influencer's email
              client_name: client.accountName, // Assuming client.name holds the client name
              ig_username: influencer.instagramHandle // Use the influencer's Instagram username
            }),
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const data = await response.json();
          console.log('API response:', data);
        } catch (error) {
          console.error('Error calling the API:', error);
        }
      } catch (error) {
        console.error(`Error creating collaboration for influencer ${influencerID}:`, error);
      }
    }

    // Here you should update the client's remainingCollabRequests in your database
    // This is just a placeholder, replace with actual database update
    console.log(`Updated client's remaining collab requests to: ${client.remainingCollabRequests}`);

    history.goBack();
  };
  return (
    <Box>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        height="calc(100vh - 135px)"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <Box pt="25px" left={0} width="100%">
          <Flex m="20px" justifyContent="space-between" alignItems="center">
            <Flex direction="column">
              <Text fontWeight="bold" fontSize="25px" >
                FoodFluence (Beta)
              </Text>
            </Flex>
            {campaignID && 
            (<Flex direction="column" align="center" gap={2}>
              <Button
                colorScheme="green"
                size="lg"
                variant="solid"
                onClick={() => handleCampaignLaunched()}
              >
                Finish
              </Button>
              <Text fontSize="sm" color="gray.500">
                You can add influencers later
              </Text>
            </Flex>)}
          </Flex>

          <Box p="20px">
            {campaignID ?
              (<>
                <Text mb="8px" fontWeight="bold" fontSize="22px">Select Influencers</Text>
                <Text fontSize="sm" color="gray.500" mb="16px">
                  <Text as="span" color={(client.remainingCollabRequests - totalCreators - selectedInfluencers.size) < 10 ? "red.500" : (client.remainingCollabRequests - totalCreators - selectedInfluencers.size) < 31 ? "yellow.500" : "green.500"}>{client.remainingCollabRequests - totalCreators - selectedInfluencers.size}</Text> Collab Requests Remaining
                </Text>
              </>) :
              (<>
                <Text mb="8px" fontWeight="bold" fontSize="22px">Find Influencers</Text>
                <Text fontSize="sm" color="gray.500" mb="16px">
                  <Text as="span" color={(client.remainingCollabRequests - totalCreators - selectedInfluencers.size) < 10 ? "red.500" : (client.remainingCollabRequests - totalCreators - selectedInfluencers.size) < 31 ? "yellow.500" : "green.500"}>{client.remainingCollabRequests - totalCreators - selectedInfluencers.size}</Text> Collab Requests Remaining
                </Text>
              </>)
            }

            <Input
              placeholder="Search by name, email, or Instagram handle"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              mb="20px"
              bg="gray.200"
              _placeholder={{ color: 'gray.400' }}
            />

            <Flex gap="10px" mb="20px">
              <Select
                placeholder="State"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  setSelectedCity("");
                }}
                width={200}
              >
                {[...uniqueStates].sort().map((state) => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </Select>
              <Select
                placeholder="City"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}  
                isDisabled={!selectedState}
                width={200}
              >
                {[...uniqueCities].sort().map((city) => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </Select>
              <Select
                placeholder="Follower Count"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={followerRange}
                onChange={(e) => setFollowerRange(e.target.value)}
                width={200}
              >
                {Object.keys(followerRanges).map((text, index) => (
                  text != "" ?
                    (<option key={index} value={text}>{text}</option>) : (<></>)
                ))}
              </Select>
              <Button
                rightIcon={<Icon as={FaStar} color={filterFeatured ? "white" : "#0080FE"} />}
                borderWidth={"thin"}
                borderColor={filterFeatured ? "green" : "black"}
                backgroundColor={filterFeatured ? "green" : "white"}
                color={filterFeatured ? "white" : "black"}
                onClick={() => setFilterFeatured(!filterFeatured)}
                fontWeight={"normal"}
                borderRadius={10}
                variant="ghost"
                _hover={{ borderColor: "grey.400" }}
              >
                Featured
              </Button>
              {campaignID ? ( 
                <Box ml={3} alignSelf={"center"}>
                  <Checkbox 
                    isChecked={filterSelected}
                    onChange={() => setFilterSelected(!filterSelected)}
                    colorScheme='green'
                    border={filterSelected ? "0px solid" : "1px solid"}
                    borderRadius="sm"
                    size='lg'
                  />
                </Box>
              ) : (
                <Button onClick={() => setFilterBookmarked(!filterBookmarked)} variant="ghost">
                  {filterBookmarked ?
                    (<FaBookmark color="green" />) : (<FaRegBookmark/>)
                  }
                </Button>
              )}
            </Flex>
          </Box>
        </Box>

        <Box overflow="auto" flex="1" p="20px">
          <InfluencersList 
            key={currPage} 
            influencers={visibleInfluencers[currPage]} 
            bookmarkedInfluencers={client.bookmarkedInfluencers}
            clientID={client.id} 
            creatingCampaign={campaignID!=null} 
            handleInfluencerSelected={toggleInfluencerSelected}
            selectedInfluencers={selectedInfluencers} 
          />
        </Box>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          totalPages={visibleInfluencers.length}
        />
      </Box>
    </Box>
  );
}
