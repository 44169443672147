import React, { createContext, useState, useEffect } from "react";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { firestore } from "../firebase";

export const InfluencersDataContext = createContext();

export const InfluencersDataProvider = ({ children }) => {
    const [influencers, setInfluencers] = useState({});
    useEffect(() => {
        const influencerCollectionRef = collection(firestore, "influencers");

        const unsubscribe = onSnapshot(influencerCollectionRef, (snapshot) => {
            const fetchedInfluencers = {};
            snapshot.forEach((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    fetchedInfluencers[docSnap.id] = { id: docSnap.id, ...data };
                }
            });
            setInfluencers(fetchedInfluencers);
        });

        // Cleanup the listener when the component is unmounted
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <InfluencersDataContext.Provider value={influencers}>
            {children}
        </InfluencersDataContext.Provider>
    );
};

export default InfluencersDataProvider;
