import React, { useContext } from "react";
import AccountsDataProvider from "contexts/accountsDataContext";

import { AdminDataContext } from "contexts/adminDataContext";
import DashboardWrapped from "./dashboard";
import CustomersDataProvider from "contexts/customersDataContext";
import AutopilotDataProvider from "contexts/autopilotDataContext";
import TouchpointsDataProvider from "contexts/touchpointsDataContext";
import FoodFluenceDataProvider from "contexts/foodfluenceDataContext";
import InfluencerCampaignsDataProvider from "contexts/influencerCampaignsDataContext";
import InfluencersDataProvider from "contexts/influencersDataContext";

export default function AdminLayout(props: { [x: string]: any }) {
  const { adminData, isLoading } = useContext(AdminDataContext);

  if (adminData) {
    console.table("adminData", adminData);
  }

  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "ratfhs9y",
    email: adminData?.email,
  });

  return (
    <AccountsDataProvider clientIds={adminData?.clientIDs}>
      <AutopilotDataProvider clientIds={adminData?.clientIDs}>
        <TouchpointsDataProvider clientIds={adminData?.clientIDs}>
          <FoodFluenceDataProvider>
            <InfluencerCampaignsDataProvider clientIds={adminData?.clientIDs}>
              <InfluencersDataProvider>
                <CustomersDataProvider clientIds={adminData?.clientIDs}>
                  <DashboardWrapped />
                </CustomersDataProvider>
              </InfluencersDataProvider>
            </InfluencerCampaignsDataProvider>
          </FoodFluenceDataProvider>
        </TouchpointsDataProvider>
      </AutopilotDataProvider>
    </AccountsDataProvider>
  );
}
