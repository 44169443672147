import React, { useContext, useState } from "react";
import { Box, Flex, Text, Button, useBreakpointValue } from "@chakra-ui/react";
import {
  useHistory,
  useRouteMatch,
  useParams,
  Switch,
  Route,
} from "react-router-dom";
import WelcomeBanner from "./components/WelcomeBanner";
import ActiveCampaignsTable from "./components/ActiveCampaignsTable";
import FeaturedInfluencers from "./components/FeaturedInfluencers";
import Search from "./pages/search";

import AccountsDropdown from "./components/accountsDropDown";
import { InfluencerCampaignsDataContext } from "contexts/influencerCampaignsDataContext";
import { InfluencersDataContext } from "contexts/influencersDataContext";
import { AccountsDataContext } from "contexts/accountsDataContext";
import ActiveCampaign from "./components/ActiveCampaign";
import { Influencer, InfluencerCampaign, CollaborationsData } from "./types";

export default function FoodFluence() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const influencerData: Record<string, Influencer> = useContext(
    InfluencersDataContext
  );
  const [filteredClientID, setFilteredClientID] = useState<string>("");

  const influencerCampaigns: Record<
    string,
    Record<string, InfluencerCampaign>
  > = useContext(InfluencerCampaignsDataContext);

  const [selectedAccountName, setSelectedAccountName] = useState<string>("");

  const accountsData = useContext(AccountsDataContext);

  const handleSearchClick = () => {
    history.push(`${path}/search?clientID=${filteredClientID}`);
  };

  const handleCampaignClick = (campaignId: string) => {
    history.push(`${path}/campaign/${campaignId}`);
  };

  let clientAccounts = [];
  for (let account in accountsData) {
    if (account && accountsData[account]) {
      clientAccounts.push({
        ...accountsData[account],
      });
    }
  }
  const handleAccountNameChange = (name: string) => {
    setSelectedAccountName(name);
  };

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });

  const FoodFluenceMain = () => (
    <Box>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        minHeight="calc(100vh - 135px)"
        position="relative"
        display="flex"
        flexDirection="column"
        p={{ base: 2, md: 3 }}
      >
        <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
          <Box flex="1" ml={-0} width="auto">
            <WelcomeBanner
              clientID={filteredClientID}
              accountName={selectedAccountName}
            />
          </Box>
          {accountsData && Object.keys(accountsData)?.length > 0 && (
            <Box width={{ base: "100%", md: "auto" }} mt={{ base: 1, md: 0 }}>
              <AccountsDropdown
                filteredClientId={filteredClientID}
                clientAccounts={clientAccounts}
                setFilteredClientId={setFilteredClientID}
                setSelectedAccountName={handleAccountNameChange}
                selectedAccountName={selectedAccountName}
              />
            </Box>
          )}
        </Flex>

        <Box mt={-1} pl="5%" width="100%">
          <ActiveCampaignsTable
            influencerCampaigns={influencerCampaigns[filteredClientID]}
            onCampaignClick={handleCampaignClick}
          />
        </Box>
        <Flex justifyContent="flex-start" alignItems="center" mt={4}>
          <Text fontSize="2xl" fontWeight={"bold"} ml={20}>
            Featured Influencers:
          </Text>
          <Button
            bg="#4CAF50"
            color="white"
            borderRadius="full"
            fontWeight="bold"
            onClick={() => handleSearchClick()}
            size={buttonSize}
            py={2}
            px={6}
            height="auto"
            width="auto"
            _hover={{ bg: "#45a049", transform: "translateY(-2px)" }}
            _active={{ bg: "#3d8b40" }}
            transition="all 0.2s"
            boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            ml="6"
          >
            Search All
          </Button>
        </Flex>
        <Box pl={8}>
          <FeaturedInfluencers />
        </Box>
      </Box>
    </Box>
  );

  const ActiveCampaignWrapper = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    const campaign = influencerCampaigns[filteredClientID][campaignId];

    if (!campaign) {
      return <div>Campaign not found</div>;
    }

    return (
      <ActiveCampaign
        campaignName={campaign.name}
        campaignId={campaignId}
        collaborations={campaign.collaborations}
        influencers={influencerData}
        clientID={filteredClientID}
      />
    );
  };

  return (
    <Switch>
      <Route exact path={path}>
        <FoodFluenceMain />
      </Route>
      <Route path={`${path}/search`}>
        <Search />
      </Route>
      <Route path={`${path}/campaign/:campaignId`}>
        <ActiveCampaignWrapper />
      </Route>
    </Switch>
  );
}
