import React from 'react';
import ReactApexChart from 'react-apexcharts';

type ChartProps = {
	[x: string]: any;
};
type ChartState = {
	chartData: any[];
	chartOptions: any;
};

class CommunityLineChart extends React.Component<ChartProps, ChartState> {
	constructor(props: { chartData: any[]; chartOptions: any }) {
		super(props);

		this.state = {
			chartData: [],
			chartOptions: {}
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.chartData !== prevState.chartData) {
			return { chartData: nextProps.chartData };
		}
		return null; // No change
	}

	componentDidMount() {
		this.setState({
			chartData: this.props.chartData,
			chartOptions: this.props.chartOptions
		});
	}

	render() {
		return (
			<ReactApexChart
				options={this.state.chartOptions}
				series={this.state.chartData}
				type='line'
				width='100%'
				height='100%'
			/>
		);
	}
}

export default CommunityLineChart;
